import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';

function Discussions() {
    const dispatch = useDispatch();
    const title = 'Discussions';
    const [discussions, setDiscussion] = useState([]);
    const { id } = useParams();
    const [value, setValue] = useState('');
    const [expandedDiscussion, setExpandedDiscussion] = useState(null); // Track which discussion is expanded
    const navigate = useNavigate();

    const getDiscussionsOfProject = () => {
        API.get(`/admin/get-discussions-project/${id}`)
            .then((response) => {
                const sortedDiscussions = response.data.data.sort((a, b) => 
                    new Date(b.createdAt) - new Date(a.createdAt) // Sort by createdAt in descending order
                );
                console.log(sortedDiscussions);
                setDiscussion(sortedDiscussions);
            }).catch((error) => {
                console.log(error);
            });
    }
    
    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const filterBySearch = discussions.filter(discussion => { 
        if (typeof value === 'undefined' || value.trim() === '') {
            return discussion;
        } else {
            const lowercasedValue = value.toLowerCase();
            return (
                discussion?.title.toLowerCase().includes(lowercasedValue) ||
                discussion?.description.toLowerCase().includes(lowercasedValue) ||
                discussion?.addedByUser?.firstName.toLowerCase().includes(lowercasedValue) ||
                new Date(discussion?.createdAt).toLocaleString().includes(value)
            );
        }
    });

    useEffect(() => {
        dispatch(pageTitle(title));
        getDiscussionsOfProject();
    }, []);

    const handleReadMoreToggle = (discussionId) => {
        setExpandedDiscussion(expandedDiscussion === discussionId ? null : discussionId);
    }

    const truncateDescription = (description) => {
        if (description.length > 50) {
            return `${description.slice(0, 50)}...`;
        }
        return description;
    }
    

    return (
        <div className='main-dashboard-admin'>

            <div className='discussion-bar'>


                <div className="dis-head">
                <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(`/clients/client-detail/project-detail/${id}`)}> <i class="fas fa-sign-out-alt"></i></button>

                    <h6>Discussions</h6>
                </div>
                <div className='search-bar'>
                    <div className='dis-btn'>
                        <Link to={`/clients/client-detail/project-detail/discussions/new-discussion/${id}`}>New Discussion</Link>
                    </div>
                </div>
            </div>
            <div className="teamInfoTable discussion-table">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="data">Discussion ID</th>
                            <th className="name">User</th>
                            <th className="projectName">Discussion Title</th>
                            <th className="message">Description</th>
                            <th className="createdAt">Date &amp; Time Set</th>
                            <th className="status">Status</th>
                            <th>Comments</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filterBySearch?.length > 0 ?
                                filterBySearch.map((discussion) => (
                                    <tr key={discussion?.id}>
                                        <td className="projectName">
                                        <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>D - {discussion?.serialNo}</Link></td>
                                        <td className="name">
                                                <LazyImageWithSpinner 
                                                    src={discussion?.addedByUser?.profilePictureUrl} 
                                                    alt="Profile" 
                                                    style={{ maxWidth: '50px', marginRight: '10px' }} 
                                                />
                                                <p>{discussion?.addedByUser?.firstName}</p>
                                                </td>

                                        
                                        <td className="message"><Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'><p>{discussion?.title}</p></Link></td>
                                        <td className="createdAt">
                                                <p>
                                                {expandedDiscussion === discussion?.id ? 
                                                <span>{new DOMParser().parseFromString(discussion.description, 'text/html').body.textContent}</span> :
                                                <span>{new DOMParser().parseFromString(truncateDescription(discussion.description), 'text/html').body.textContent}</span>
                                            }
                                            {/* Display 'Read More' if there are more than 50 characters in the description */}
                                            {discussion.description.length > 50 && (
                                                <button onClick={() => handleReadMoreToggle(discussion?.id)}>
                                                    {expandedDiscussion === discussion?.id ? 'Read Less' : 'Read More'}
                                                </button>
                                            )}

                                                </p>
                                            </td>


                                        <td className="view">
                                        <p>
                                            {discussion.comments.length > 0
                                                ? new Date(discussion.comments[0].createdAt).toLocaleString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true, // Ensures AM/PM format
                                                })
                                                : new Date(discussion.createdAt).toLocaleString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true, // Ensures AM/PM format
                                                })}
                                            </p>
                                        </td>
                                        <td className="view">
                                            {discussion?.status}
                                         </td>
                                        <td className="com">
                                        {discussion?.comments?.length === 0 ? 'No' : discussion?.comments?.length} Comments
                                        </td>
                                        <td className="com">
                                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`} className='sr-cstm-whole-link'>View</Link>
                                        </td>
                                    </tr>
                                ))
                                : 'No discussion found'
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Discussions;
