import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Layouts from './layouts/Layouts';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ClientDashboard from './components/dashboard/ClientDashboard';
import ClientDetail from './components/clients/ClientDetail';
import ClientsList from './components/clients/ClientsList';
import ProjectDetail from './components/clients/ProjectDetail';
import OpportunityList from './components/opportunity/OpportunityList';
import AddOpportunity from './components/opportunity/AddOpportunity';
import Teams from './components/teams/Teams';
import AddTeam from './components/teams/AddTeam';
import TeamMemberDetail from './components/teams/TeamMemberDetail';
import Checklists from './components/checklist/Checklists';
import Details from './components/opportunity/Details';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AddSop from './components/SOPs/AddSop';
import SopDetail from './components/SOPs/SopDetail';
import Sop from './components/SOPs/Sop';
import EmailLogs from './components/Logs/EmailLogs';
import EmailLogsDetail from './components/Logs/EmailLogsDetail';
import PasswordVault from './components/clients/PasswordVault';
import Discussions from './components/clients/Discussions';
import AddDiscussion from './components/clients/AddDiscussion';
import DiscussionDetail from './components/clients/DiscussionDetail';
import TimeTracking from './components/timeTracking/TimeTrackingListing';
import API from './AxiosConfig';
import AcceptInvite from './components/opportunity/acceptingInvitation';
import ArchiveProject from './components/clients/archivedProjects';
import AllActivityLogs from './components/opportunity/ActivityLogs/ViewAllLogs';
import AddClientProject from './components/clients/AddClientProject';
import Progress from './components/progress/Progress';
import MyProfile from './components/myProfile';
import ManagePermissions from './components/managePermissions';
import ActivityLogs from './components/Logs/ActivityLogs';
import SearchDetails from './components/searchDetails';
import ControlledBoard from './components/Board';
import KanbanBoard from './components/Board/index';
import AddManualTime from './components/timeTracking/addManualTime';
import TaskDetailPage from './components/Board/taskDetailPage';
import ArchivedTaskPage from './components/Board/archivedTaskPage';
import OpportunityData from './components/clients/OpportunityData';
import TimeTrackingforProject from './components/timeTracking/ParticularProject/timeTrackingforProject';
import ViewChecklist from './components/clients/viewChecklist';
import ArchivedClientsList from './components/clients/archivedClients';
import { useSelector } from 'react-redux';
import EditTeam from './components/teams/EditTeam';
import AddManualTimeforProject from './components/timeTracking/ParticularProject/addmanualTimeforProject';
import DiscussionStatusList from './components/projectStatus/discussionStatusList';
import Templates from './components/templates/listTemplates';
import AddTemplate from './components/templates/AddTemplate';

function ProtectedRoute({ element, allowedRoles }) {
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuthenticated = await checkJWTToken();
        if (isAuthenticated) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            setUserRole(parsedUser.role.role);
          }
        } else {
          navigate("/", { replace: true });
        }
      } catch (error) {
        console.error("JWT token check failed:", error);
        navigate("/", { replace: true });
      } finally {
        setIsAuthChecked(true);  // Mark authentication check as completed
      }
    };

    checkAuth();
  }, [navigate]);

  if (!isAuthChecked) {
    return <div>Loading...</div>;  // Show a loading state while checking auth
  }

  if (allowedRoles && allowedRoles.length > 0 && allowedRoles.includes(userRole)) {
    return element;
  } else {
    return (
      <div className="main-dashboard-admin">
        <p>You don't have access to this.</p>
      </div>
    );
  }
}

function checkJWTToken() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');

    if (!token) {
      resolve(false);
      return;
    }

    API.post('/verify-token', { "token": token })
      .then((response) => {
        if (response.status === 200) {
          // Store the user data in localStorage (if token is valid)
          localStorage.setItem('user', JSON.stringify(response.data.user));
          resolve(true);
        } else if (response.status === 202 || (response.data.error && response.data.error.name === 'TokenExpiredError')) {
          localStorage.removeItem('token');
          resolve(false); 
        } else {
          localStorage.removeItem('token');
          resolve(false);
        }
      })
      .catch((error) => {
        console.error("JWT token check failed:", error);
        localStorage.removeItem('token');
        localStorage.clear();
        reject(error);
      });
  });
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [currentToken, setCurrentToken] = useState('');
  const getCurrentToken = localStorage.getItem('token');

  if (!currentToken && getCurrentToken) {
    setCurrentToken(getCurrentToken);
  }

  useEffect(() => {
    if (currentToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentToken]);

  const handleLogin = (token) => {
    setCurrentToken(token);

  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login handleLogin={handleLogin} isLoggedIn={isLoggedIn} />} />
          <Route element={<Layouts isLoggedIn={isLoggedIn} handleLogin={handleLogin} />}>
          {/* <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard />} allowedRoles={['team', 'admin']} />} /> */}
            <Route path='/clients' element={<ProtectedRoute element={<ClientsList />} allowedRoles={['admin', 'team']} />} />
            <Route path='/archived-clients' element={<ProtectedRoute element={<ArchivedClientsList />} allowedRoles={['admin', 'team']} />} />
            <Route path='/clients/client-detail/:id' element={<ClientDetail />} />
            <Route path='/clients/archive-client-detail/:id' element={<ArchiveProject />} />
            <Route path='/clients/client-detail/project-detail/:id' element={<ProjectDetail />} />
            <Route path='/clients/client-detail/project-detail/password-vault/:id' element={<PasswordVault />} />
            <Route path='/clients/client-detail/project-detail/discussions/:id' element={<Discussions />} />
            <Route path='/clients/client-detail/project-detail/discussions/discussion-detail/:id/:sectionID?' element={<DiscussionDetail />} />
            <Route path='/clients/client-detail/project-detail/discussions/new-discussion/:id/:discussion_id?' element={<AddDiscussion />} />
            <Route path='/opportunities' element={<OpportunityList />} />
            <Route path='/team' element={<Teams />} />
            <Route path='/addteam' element={<AddTeam />} />
            <Route path='/update-team/:id' element={<AddTeam />} />
            <Route path='/opportunities/details/:id' element={<Details />} />
            <Route path='/opportunities/addopportunity' element={<AddOpportunity />} />
            <Route path='/teamMember-detail/:id' element={<TeamMemberDetail />} />
            <Route path='/sop/:projectTypeId?' element={<Sop />} />
            <Route path='/add-sop/:projectId?/:id?' element={<AddSop />} />
            <Route path='/sop-detail/:projectId?/:id?' element={<SopDetail />} />
            <Route path='/checklists' element={<Checklists />} />
            <Route path='/email-logs' element={<EmailLogs />} />
            <Route path='/email-logs/emaillogdetails/:id' element={<EmailLogsDetail />} />
            <Route path='/log-detail/:id' element={<EmailLogsDetail />} />
            <Route path='/timeTracking' element={<TimeTracking />} />
            <Route path='/view-activities/:projectid' element={<AllActivityLogs />} />
            <Route path='/clients/add-new-Project/:id' element={<AddClientProject />} />
            <Route path='/progress' element={<Progress />} />
            <Route path='/myProfile' element={<MyProfile />} />
            <Route path='/managePermissions' element={<ManagePermissions />} />
            <Route path='/viewAll/Activity' element={<ActivityLogs />} />
            <Route path='/searchedData' element={<SearchDetails />} />
            <Route path='/viewBoard/:id' element={<KanbanBoard />} />
            <Route path='/addManualTime' element={<AddManualTime />} />
            <Route path='/taskDetailPage/:taskId' element={<TaskDetailPage />} />
            <Route path='/archivedTask/:id' element={<ArchivedTaskPage />} />
            <Route path='/clients/client-detail/project-detail/opportunityDetails/:id' element={<OpportunityData />} />
            <Route path='/timeTracking/:projectId' element={<TimeTrackingforProject />} />
            <Route path='/addManualTime/:projectId' element={<AddManualTimeforProject />} />
            <Route path='/projectDetail/:projectId/:checklistId' element={<ViewChecklist />} />
            <Route path='/teamMember-edit/:id' element={<EditTeam />} />
            <Route path='/projectStatus' element={<DiscussionStatusList />} />
            <Route path='/templates/:projectTypeId?' element={<Templates />} />
            <Route path='/add-template/:projectId?/:id?' element={<AddTemplate />} />
        </Route>
          <Route path='/forgot-password' element={<ForgotPassword />}  />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;