import React from 'react';
import { useLocation } from 'react-router-dom';

function SearchDetails() {
    const location = useLocation();
    const { searchData = [], query = '' } = location.state || {}; // Default to empty array and empty string

    // Function to strip HTML tags from a string
    function stripHtmlTags(html) {
        if (!html) return '';
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    // Function to highlight the query in the text
    const highlightText = (text, query) => {
        if (!query) return text; // If no query, return text as is
        const regex = new RegExp(`(${query})`, 'gi'); // Case insensitive match of the query
        return text.split(regex).map((part, index) => 
            part.toLowerCase() === query.toLowerCase() ? 
            <span key={index} style={{ backgroundColor: '#ffc', color: '#000', textDecoration: 'underline' }}>{part}</span> : 
            part
        );
    };

    const searchedComments = searchData?.comments || [];

    return (
        <div className="main-dashboard-admin">
            <div className='search-main-outer'>
                <div className='search-heading'>
                    <h2>Searching for <span>{query}</span></h2>
                </div>
                <div className='search-outer-sec'>
                    {searchedComments.length > 0 ? (
                        searchedComments.map((item, index) => (
                            <div key={index} className="search-data">
                                <div className='search-data-inner'>
                                    <div className='search-date'>
                                        <span>{new Date(item?.createdAt).toLocaleString()}</span>
                                    </div>
                                    <div className='progress-client-details'>
                                        <div className="progress-client">
                                            <img src='/assets/images/progress-img.png' alt="Progress" />
                                        </div>
                                        <div className='progress-date'>
                                            <a href={`clients/client-detail/project-detail/discussions/discussion-detail/${item.discussion?.id}`}>
                                                Re: {item.discussion?.title}
                                            </a>
                                            <div className='text-pro'>
                                                {/* Highlighted Comment Text */}
                                                <p>{item.user?.firstName} - {highlightText(stripHtmlTags(item?.comment), query)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No search results found.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SearchDetails;
