import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link,NavLink, useParams } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Bars } from 'react-loader-spinner';  
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';

function AddSop() {
    const navigate  = useNavigate();
    const dispatch = useDispatch();
    const [projectTypes, setprojectTypes] = useState([]);
    const [sops, setSops] = useState([]);
    const [deleteableId,setDeleteableId] = useState('');
    const {projectTypeId} = useParams();
    const [show,setShow] = useState(false);
    const title = "Standard Operating Procedures";
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
       
    const handleClick = (e) => {
        const id = e.target.value;
        navigate(`/sop/${id}`);
        getSops(id);
    };
    const getProjectTypes = () => {
        setLoading(true);
        API.get('/admin/project-types')
        .then((response) => {
            setLoading(false);
            setprojectTypes(response.data.data);
            getSops();
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }
    const getSops = (id) => {
        setLoading(true);
        const projectId = id ? id : (projectTypeId?projectTypeId:1) ; 
        const token = sessionStorage.getItem('token');
        API.get(`/admin/sop/${projectId}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
          .then((response)=>{
            setLoading(false);
            setSops(response.data.data);
          }).catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Something went wrong");
        });
    }

    const handleDelete = (e)=>{
        const sopId = e.target.value;
        setDeleteableId(sopId);
        setShow(true)
    }
    const handleConfirmDelete = () =>{
        setLoading(true);
        const token = sessionStorage.getItem('token');
        API.delete(`/admin/delete-sop/${deleteableId}`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).
        then((response)=>{
            setLoading(false);
            toast.success("SOP deleted Successfully");
            setShow(false)
            getSops(response.data.data)
        }).catch((e)=>{
            setLoading(false);
            toast.error("Something went wrong.");
            setShow(false)
            console.log(e);
        });
    }
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        dispatch(pageTitle(title));
        getProjectTypes();
    },[])
    dispatch(setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Standard Operating Procedures", path: "/sop", active: true },
    ]));
    return (
        <div className="main-dashboard-admin">
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <div className="standard-operating-procedures">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                </ul>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {
                        projectTypes && projectTypes.length>0 ? projectTypes.map((item,key)=>
                            <li className="nav-item" role="presentation">
                                {(projectTypeId ? projectTypeId == item?.id : key === 0)?
                                <button className="nav-link active" value={item.id} onClick={handleClick} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{item.name}</button>
                                : 
                                <button className="nav-link" value={item.id} onClick={handleClick} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{item.name}</button>
                                }
                            </li>
                        ) : 'Data not found'
                    }
                </ul>
                {userData?.user?.roleId === 1 ? (
                    <div className='button-add-sop'>
                    <Link to={`/add-sop/${projectTypeId}`} className="global-icon-btn">Add New SOP</Link>
                    </div>
                ) : ''}
            </div>

            <div className="standard-operating-procedures">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="four-boxes-section">
                        {sops && sops.length>0 ? sops.map((item)=>( 
                            <div className="single-box-boxes">
                                <div className="img-boxes-single">
                                {
                                    (item.media.split('.').pop() == 'jpg' || item.media.split('.').pop() == 'jpeg' || item.media.split('.').pop() == 'png' || item.media.split('.').pop() == 'svg')?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src={item.file} alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'csv')
                                    ?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/csv-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'doc')
                                    ?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="file"/></Link>
                                    : 
                                    (item.media.split('.').pop() == 'html')
                                    ?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/html-5-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'xml')
                                    ?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'zip')
                                    ?
                                    <Link to={`/sop-detail/${item.id}`}><LazyImageWithSpinner src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'pdf')
                                    ?   
                                    // <iframe src={item.file} style={{ width: '100%', height: '600px' }} />
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/pdf-file-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    (item.media.split('.').pop() == 'mp4')
                                    ?
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/video-streaming-outline-svgrepo-com.svg" alt="file"/></Link>
                                    :
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}><LazyImageWithSpinner src="/assets/images/file-unknown.svg" alt="file"/></Link>
                                }
                                </div>
                                <div className="content-boxes-single">
                                    <Link to={`/sop-detail/${projectTypeId}/${item.id}`}>
                                        {
                                            <h4>{item?.title}</h4>
                                        }
                                        {
                                            <p>
                                            {'Added by ' + item.username + ' on ' + new Date(item.createdAt).toLocaleString('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true, // Ensures AM/PM format
                                            })}
                                                                                        </p>
                                        }
                                    </Link>
                                    {userData?.user?.roleId === 1 ? (
                                    <div className='sop-edit-delete'>
                                        <Link to={`/add-sop/${projectTypeId}/${item?.id}`} className="small-box-button global-icon-btn" value={item?.id}>Edit</Link>
                                        <button className='delete-box-button global-icon-btn' onClick={handleDelete} value={item?.id}>Delete</button>
                                    </div>
                                    ) : `` }
                                </div>
                            </div>
                         )):(
                            <div className="no-data-found">
                                <span>Data not found.</span>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                    <div className="tab-pane fade" id="ppc" role="tabpanel" aria-labelledby="ppc-tab">...</div>
                    <div className="tab-pane fade" id="design" role="tabpanel" aria-labelledby="design-tab">...</div>
                    <div className="tab-pane fade" id="development" role="tabpanel" aria-labelledby="development-tab">...</div>
                    <div className="tab-pane fade" id="hosting" role="tabpanel" aria-labelledby="hosting-tab">...</div>
                    <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">...</div>
                </div>
            </div>
            <Modal centered show={show} onHide={handleClose} className='add-team-modal'>
            <Modal.Header closeButton>
            <h4 className="modal-title">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="19.2"
                viewBox="0 0 24 19.2"
                >
                <path
                    id="Icon_awesome-user-plus"
                    data-name="Icon awesome-user-plus"
                    d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
                />
                </svg>
                Delete SOP
            </h4>
            </Modal.Header>
            <Modal.Body>
            <div className="calender-box">
                <div className="question-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="86.875" height="86.875" viewBox="0 0 86.875 86.875">
                    <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z" transform="translate(-0.563 -0.563)" />
                </svg>
                </div>
                <p>Are you sure you want to Delete a SOP ?</p>
            </div>
            <div className="form-btn-rows">
                <button  type="button " onClick={handleConfirmDelete} className="btn global-icon-btn orange-btn ">Yes</button>
                <button  type="button" onClick={handleClose} className="btn global-icon-btn ">No</button>  
            </div>
            </Modal.Body>
            </Modal>
          <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />
        </div>
    )
}
export default AddSop

