import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { toast, ToastContainer } from "react-toastify";
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import { useMemo } from "react";

function DiscussionStatusList() {
    const dispatch = useDispatch();
    const title = "Project Status";
    const [Discussions, setDiscussions] = useState([]);
    const [filteredDiscussions, setFilteredDiscussions] = useState([]);
    const [selectedType, setSelectedType] = useState("");
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);

    useEffect(() => {
        dispatch(pageTitle(title));
        getOpportunity();
        dispatch(setBreadcrumbs([
            { label: "Home", path: "/" },
            { label: "Project Status", path: "/projectStatus", active: true }
        ]));
    }, [dispatch, title]);

    const getOpportunity = () => {
        setLoading(true);
        API.get("/admin/discussions")
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    setLoading(false);
                    setDiscussions(response.data.discussions);
                    setFilteredDiscussions(response.data.discussions); // Initialize filtered discussions
                } else {
                    setLoading(false);
                    toast.error("Error while getting the data", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };


    const discussionCounts = useMemo(() => {
        const counts = {};
        Discussions.forEach((discussion) => {
            if (counts[discussion.status]) {
                counts[discussion.status]++;
            } else {
                counts[discussion.status] = 1;
            }
        });
        return counts;
    }, [Discussions]);

    const handleTypeChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedType(selectedValue);
    
        if (selectedValue === "") {
            setFilteredDiscussions(Discussions); 
        } else {
            const filtered = Discussions.filter(discussion => discussion.status === selectedValue);
            setFilteredDiscussions(filtered);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
      
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
      
        // Get hours, minutes, and seconds
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
      
        // Determine AM or PM
        const amPm = hours >= 12 ? 'PM' : 'AM';
        
        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 becomes 12 (midnight)
      
        // Pad month, day, hours, minutes, and seconds with leading zeros if needed
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      
        return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amPm}`;
      };
      

    return (
        <div className="main-dashboard-admin oppounity">
            {loading && (
                <div className='rotate-line-loader'>
                    <Bars
                        visible={true}
                        height="150"
                        width="150"
                        color="orange"
                        strokeColor='orange'
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                    />
                </div>
            )}

            <div className="addOpportunities">
                <div className="selectValues">
                    <div className="fields">
                        <label htmlFor="types">Status:</label>
                        <div className="selectDropdown">
                            <select name="types" id="types" value={selectedType} onChange={handleTypeChange}>
                                <option value="">All</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Work In Progress">Work In Progress</option>
                                <option value="QA Review">QA Review</option>
                                <option value="PM Review">PM Review</option>
                                <option value="Project Won">Management Review</option>
                                <option value="Client Review">Client Review</option>
                                <option value="Pending Go-Live">Pending Go-Live</option>
                                <option value="Complete">Complete</option>
                            </select>
                            <div className="dropIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
               </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="stepsWrapper">
            <ul>
                {Object.entries(discussionCounts)
                    .sort(([statusA], [statusB]) => {
                    // Prioritize "None" status to appear at the very left
                    if (statusA === 'None') return -1;
                    if (statusB === 'None') return 1;
                    return 0; // Keep the rest in the original order
                    })
                    .map(([status, count]) => (
                    <li 
                        className="items" 
                        key={status}
                        onClick={() => handleTypeChange({ target: { value: status } })} 
                    >
                        <div className="steps">
                        <div className="countDigit">
                            <span>{count}</span>
                        </div>
                        <div className="amountBlock">
                            <p>{status}</p>
                        </div>
                        </div>
                    </li>
                    ))}
                </ul>

            </div>


            <div className="opportunityCards">
                {filteredDiscussions.map((discussion) => (
                    <div className="cards" key={discussion.id}>
                        <div className="cardHeader">
                            <a href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
                                <h3><b>Company Name:</b> {discussion.companyName}</h3>
                            </a>
                        </div>
                        <div className="cardHeader">
                            <a href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
                                <h3><b>Title:</b> {discussion.title}</h3>
                            </a>
                        </div>
                        <div className="companyName">
                            <p className="service">
                                <a href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
                                    <b>Status :</b> {discussion.status}
                                </a>
                            </p>
                        </div>
                        <div className="companyName">
                            <p className="service">
                                <a href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
                                    <b>Last Modified :</b> {formatDate(discussion.updatedAt)}
                                </a>
                            </p>
                        </div>
                        <div className="cardAmount">
        <p>{discussion.amount}</p>
        <div className="leftArrowIcon">
          <a href={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.703"
              height="16.531"
              viewBox="0 0 15.703 16.531"
            >
              <g
                id="Icon_feather-arrow-right"
                data-name="Icon feather-arrow-right"
                transform="translate(-6.5 -6.086)"
              >
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M7.5,18H21.2"
                  transform="translate(0 -3.648)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M18,7.5l6.852,6.852L18,21.2"
                  transform="translate(-3.648)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
))}
  </div>

            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default DiscussionStatusList;
