// src/components/AddTemplate.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { NavLink, Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../AxiosConfig';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import csvIcon from '../images/csv_file_logo.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { CustomUploadAdapterPlugin } from '../../utils/CustomUploadAdapter'; // Import the custom upload adapter

function AddTemplate() {
  const [formData, setFormData] = useState({ id: '', title: '', notes: '', projectTypeId: '', file: '' });
  const [titleName, setTitleName] = useState({ titleName: '' });
  const [projectTypeId, setProjectTypeId] = useState({ projectTypeId: '' });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState('');
  const [TemplateDetail, setTemplatedetail] = useState({});
  const [projectTypes, setprojectTypes] = useState([]);
  const [loader, setLoader] = useState(false);
  const { id, projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const ActiveuserId = userData?.user?.id;

  const getProjectTypes = () => {
    API.get('/admin/project-types')
      .then((response) => {
        setprojectTypes(response.data.data);
        getDetail();
      }).catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'file') {
      setFormData({ ...formData, [name]: value });
      if (name === 'title') {
        setTitleName({ ...titleName, titleName: value });
      }
      else if (name === 'projectTypeId') {
        setProjectTypeId({ ...projectTypeId, projectTypeId: value });
      }
    }
    else if (name === 'file') {
      const file = e.target.files[0];
      if (file) {
        // Set image into form data
        setFormData({ ...formData, [name]: file });

        // Show image in frontend
        const extension = file.name.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'svg'].includes(extension)) {
          setImage(URL.createObjectURL(file));
        }
        else {
          checkSetImage(file.name, file);
        }
      }
    }
  };

  // Determine which image icon to display based on file type
  function checkSetImage(imageName, data) {
    const extension = imageName.split('.').pop().toLowerCase();
    const imageMap = {
      'csv': '/assets/images/csv-svgrepo-com.svg',
      'doc': '/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg',
      'html': '/assets/images/html-5-svgrepo-com.svg',
      'xml': '/assets/images/xml-file-format-variant-svgrepo-com.svg',
      'zip': '/assets/images/compressed-file-zip-svgrepo-com.svg',
      'pdf': '/assets/images/pdf-file-svgrepo-com.svg',
      'mp4': '/assets/images/video-file.svg', // Add appropriate icon
      // Add more mappings as needed
    };

    if (imageMap[extension]) {
      setImage(imageMap[extension]);
    }
    else {
      setImage('/assets/images/file-unknown.svg');
    }
  }

  // On edition
  const getDetail = () => {
    if (id) {
      API.get(`/admin/TemplateDetail/${id}`)
        .then((response) => {
          setTemplatedetail(response.data.data);
          console.log(response.data.data);
          let data = response.data.data;
          let EditableData = {
            id: '',
            title: '',
            notes: data.notes,
            file: ''
          };
          setFormData(EditableData);
          setTitleName({ ...titleName, titleName: data.title });
          setProjectTypeId({ ...projectTypeId, projectTypeId: data.projectTypeId });
          checkSetImage(data.media, data);
        }).catch((err) => {
          console.log(err);
        });
    }
  };

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let newErrors = {};
    if (formData.projectTypeId === '' && projectTypeId.projectTypeId === '') {
      newErrors = { ...newErrors, projectTypeId: 'Please choose project type' };
    }
    if ((formData.title === '' || formData.title === undefined) && (titleName.titleName === '' || titleName.titleName === undefined)) {
      newErrors = { ...newErrors, title: 'Title is required' };
    }
    if (formData.notes.trim() === '') {
      newErrors = { ...newErrors, notes: 'Notes are required' };
    }
    if ((formData.file === '' || !formData.file) && typeof id === 'undefined') {
      newErrors = { ...newErrors, file: 'File is required' };
    }
    if (formData.file) {
      const validExtensions = ['jpg', 'jpeg', 'png', 'csv', 'doc', 'html', 'xml', 'zip', 'pdf', 'mp4'];
      const extension = formData.file.name.split('.').pop().toLowerCase();
      if (!validExtensions.includes(extension)) {
        newErrors = { ...newErrors, file: 'File extension is not allowed' };
      }
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const token = localStorage.getItem('token'); // Get token from local storage
      const data = new FormData();
      Object.keys(formData).forEach((field) => {
        data.append(field, formData[field]);
      });

      if (typeof id === 'undefined') {
        // Save
        API.post(`/admin/addTemplate/${ActiveuserId}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        })
          .then((response) => {
            toast.success("Template Created Successfully");
            const projectTypeIdValue = formData.projectTypeId;
            setTimeout(() => navigate(`/templates/${projectTypeIdValue}`), 1000);
            setFormData({ id: '', title: '', notes: '', projectTypeId: '', file: '' });
            setImage('');
          }).catch((error) => {
            toast.error("Something went wrong");
            console.log(error);
            setLoader(false);
          });
      }
      else {
        let updatedData = {
          ...formData,
          id: id,
          title: formData.title ? formData.title : titleName.titleName,
          projectTypeId: formData.projectTypeId ? formData.projectTypeId : projectTypeId.projectTypeId,
        };
        // Update data
        API.post('/admin/updateTemplate', updatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          }
        })
          .then((response) => {
            toast.success("Template Updated Successfully");
            const projectTypeIdValue = formData.projectTypeId || TemplateDetail.projectTypeId;
            setTimeout(() => navigate(`/Template/${projectTypeIdValue}`), 1000);
            setFormData({ id: '', title: '', notes: '', projectTypeId: '', file: '' });
            setImage('');
          }).catch((error) => {
            setLoader(false);
            toast.error("Something went wrong");
            console.log(error);
          });
      }
    }
    else {
      setLoader(false);
      setErrors(newErrors);
    }

  };

  const dispatch = useDispatch();
  const title = "Templates";
  useEffect(() => {
    dispatch(pageTitle(title));
    getProjectTypes();
    // eslint-disable-next-line
  }, []);

  // CKEditor configuration with custom upload adapter
  const editorConfiguration = {
    extraPlugins: [CustomUploadAdapterPlugin],
    toolbar: {
      items: [
        'heading', '|',
        'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
        'insertTable', 'undo', 'redo', 'imageUpload' // Ensure 'imageUpload' is included
      ]
    },
    image: {
      toolbar: [
        'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
      ]
    },
    // You can add more configuration options as needed
  };

  return (
    <div className="main-dashboard-admin">
      {loader &&
        <div className='rotate-line-loader'>
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor='orange'
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        </div>
      }
        {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button> */}
        <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
     

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="single-standard-op">
          <div className="left-single-standard-op">
            {image && <img alt="file" src={image} />}
            {/* If the image is a CSV file, display a specific icon */}
            {image.includes('csv-svgrepo-com.svg') && <img src={csvIcon} alt="CSV file" />}
          </div>
          <div className="right-single-standard-op">
            <div className='select-box-sop selectDropdown'>
              <label>Select Template Type</label>
              <select
                name="projectTypeId"
                className="form-select"
                aria-label="Default select example"
                onChange={handleChange}
                value={formData.projectTypeId || projectTypeId.projectTypeId}
              >
                <option value="">Choose Project Type</option>
                {
                  projectTypes.map(item =>
                    <option key={item.id} value={item.id}>{item.name}</option>
                  )
                }
              </select>
              <span className='text-danger'>{errors?.projectTypeId}</span>
            </div>

            <div className="row mt-2">
              <label>Title</label>
              <div className="col-md-12">
                <input
                  type="text"
                  name="title"
                  placeholder='Title'
                  onChange={handleChange}
                  value={formData.title || titleName.titleName}
                  className='form-control'
                />
              </div>
              <span className='text-danger'>{errors?.title}</span>
            </div>

            <div className="editor-right-standard-op mt-2">
              <label>Description</label>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration} // Apply the configuration
                data={formData.notes}
                onChange={(event, editor) => {
                  setFormData({ ...formData, notes: editor.getData() });
                }}
              />
              <span className="text-danger">{errors?.notes}</span>
            </div>

            <div className='mt-2'>
              <input type="file" name="file" onChange={handleChange} />
            </div>
            <span className='text-danger'>{errors?.file}</span>

            <div className="buttons-editor mt-3">
              <button href="javascript:void(0)" className="global-icon-btn orange-btn" type="submit" disabled={loader}>
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                {Object.keys(TemplateDetail).length !== 0 ? 'Update' : 'Save'}
              </button>
              <Link to={`/Template/${projectId}`} className="global-icon-btn">
                <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light' 
      />
    </div>
  );
}

export default AddTemplate;
