import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link ,useNavigate} from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { MultiSelect } from "react-multi-select-component";
import ArchiveModal from './Modals/archieveModal';
import UnArchieveModal from './Modals/unarchiveModal';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import AssignChecklistModal from './Modals/AssignChecklistModal';
import CreateBoard from './Modals/createBoardModel';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import {getFileDisplay, getFileDisplaydefault} from '../../utils/fileChecker';
import EditProject from './Modals/editProjectModal';
import QuickLinks from './Modals/quickLinksModal';

function ProjectDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const userData = useSelector(state => state.auth.user);
    const [projectDetail, setProjectDetail] = useState({});
    const [files, setFiles] = useState([]);
    console.log("files", files);
    const [latestComments, setLatestComments] = useState([]);
    const [images, setImages] = useState([]);
    const [image,setImage] = useState('')
    const [awaitingTeamMembers,setawaitingTeamMembers] = useState(null);
    const [selected, setSelected] = useState([]);
    const [showAddTeamMember, setShowAddTeamMember] = useState(null);
    const { projectId } = useParams();
    const [taskCount, setTaskCount] = useState(false);
    const [issueCount, setIssueCount] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showUnModal, setShowUnModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showQuick, setShowQuick] = useState(false);
    const [loader,setLoader] = useState(false);
    const [ShowChecklist, setShowChecklist] = useState(false);
    const [showBoard, setShowBoard] = useState(false);
    const [ProjectTypeName, setProjectTypeName] = useState('');
    const [SelectedChecklist, setSelectedChecklist] = useState('');
    const [initialFile, setinitialFile]= useState('');

    const refreshState = () => {
        getProjectDetail();
      };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowEdit = () => {
        setShowEdit(true);
    };

    const handleQuickLinks = () => {
        setShowQuick(true);
    };
    
    const handlesetShowBoard = () => {
        setShowBoard(true);
    };
    const handleShowUnModal = () => {
        setShowUnModal(true);
    };

    const handleClose = () => {
        getProjectDetail();
    };

    let fileCount = 0;
    let options = [];
    if(awaitingTeamMembers && awaitingTeamMembers.length>0)
    {
      awaitingTeamMembers.map((team)=>{
        if(team.id != id)
        {
          options.push({label:team['firstName']+ ' '+team['lastName'] , value : team['id'],'projectId':id})
        }
      });
    }
    useEffect(() => {
        getProjectDetail();
    }, [id]); // `id` is the dependency
   
    const getProjectDetail = () => {
        const token = localStorage.getItem('token');
        API.get(`/admin/project-detail/${id}`,{
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log("response.data", response.data);
                 setinitialFile(response.data.initialFilesWithUsers);
                setTaskCount(response.data.taskCount);
                setIssueCount(response.data.issueCount);
                setProjectDetail(response.data.projectDetail);
                setawaitingTeamMembers(response.data.awaitingTeam);
                setSomeFiles(response.data.projectDetail)
                setProjectTypeName(response.data.projectDetail.ProjectTypes)
                setSelectedChecklist(response.data.projectDetail.Checklist)
                const clientName = response.data?.projectDetail?.opportunityName;
                dispatch(pageTitle(clientName));
                dispatch(setBreadcrumbs([
                    { label: "Home", path: "/" },
                    { label: "Clients", path: "/clients" },
                    { label: response.data.projectDetail.company.name, path: `/clients/client-detail/${response.data.projectDetail.company?.id}` },
                    { label: response.data.projectDetail.opportunityName, path: `/clients/client-detail/project-detail/${response.data.projectDetail.id}` , active: true},
                ]));
    
            }).catch((e) => {
                console.log(e);
            });
    }

    const handleSelector = () => {
        setLoader(true);
        API.post('/admin/addMemberInProject',selected)
        .then((response)=>{
          setLoader(false);
          setSelected([]);
          getProjectDetail();
        }).catch((error)=>{
          console.log(error);
          setLoader(false);
        });
    }

    const handleClosecheck = () => {
        setShowChecklist(false);
    };

    function setSomeFiles(projectDetail){
        const data = 
        projectDetail?.discussions?.length > 0 ?
        projectDetail.discussions.map(discussion => 
            discussion?.comments?.length > 0 ?
                discussion?.comments.map(comment => 
                    comment?.storages?.length > 0 ?
                        comment.storages.map(file =>
                            // fileCount<=5?
                                <div>{file.originalname}</div>
                            // : ''
                        )
                        : ''
                )
            :''
        )
    : 'No file available';
    setFiles(data);
    }

    const AddMembersPopup = () =>  setShowAddTeamMember(true);
    const closeAddMembersPopup = () => setShowAddTeamMember(false);
    async function getProfilePic(profileKey) {
        try {
          const response = await API.get(`/admin/file/${profileKey}`);
          return response.data;
        } catch (e) {
          console.error(e);
        }
    }

    const [previewUrl, setPreviewUrl] = useState(null);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);

    const handlePreview = (url) => {
        setPreviewUrl(url);
        setIsPreviewVisible(true);
    };

    const handleClosePreview = () => {
        setIsPreviewVisible(false);
        setPreviewUrl(null);
    };

    useEffect(() => {
        if(selected.length>0)
        {
          handleSelector();
        } 
        else{
            getProjectDetail();
            dispatch(pageTitle(title));
        }
    }, [selected])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
      
        const month = date.getMonth() + 1; 
        const day = date.getDate();
        const year = date.getFullYear();
      
        // Get hours, minutes, and seconds
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
      
        // Pad month, day, hours, minutes, and seconds with leading zeros if needed
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      
        return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      };
    return (
        <div className='main-dashboard-admin'>
            {loader ?
            <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>
            :''}

            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
             {images?.map((imageData, index) => (
                    <LazyImageWithSpinner  src={imageData} alt={`Profile ${index}`} />
                ))
             }
            {userData?.user?.roleId === 1 ? (
            <div className='project-btn'>
                       <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
                <div class="sr-cstm-combo pro-sr-cstm-combo">
                    <Link className='btn btn-primary' to={`/clients/client-detail/project-detail/opportunityDetails/${id}`} >View Project Scope</Link>
                    <Link to={`/timeTracking/${id}`} >Time Tracking</Link>
                    <Link className='btn btn-primary' to={`/clients/client-detail/project-detail/password-vault/${projectDetail?projectDetail.id:''}`} >Password Vault</Link>

                    <button className='sr-cstm-primary' onClick={()=>{setShowChecklist(true)}} >Assign Checklist</button>
                        {/* {
                            projectDetail?.BoardId 
                            ? <Link className='btn btn-primary' to={`/viewBoard/${ projectDetail?.BoardId }`} >View Board</Link>
                            : <button className='sr-cstm-primary' onClick={handlesetShowBoard}>Create Board</button>
                        } */}
                        {projectDetail?.Archived ? (
                        <button className="sr-cstm-primary" onClick={handleShowUnModal}>
                            Unarchive Project
                        </button>
                        ) : (
                            <button className="sr-cstm-primary" onClick={handleShowModal}>Archive Project</button> 
                            
                        )}
                        <button className='sr-cstm-primary' onClick={()=>{handleShowEdit(true)}} >Edit Project</button>

            </div>
            </div>
            ) : (
            <>
                 <div className='project-btn'>
                        <Link className='btn btn-primary' to={`/clients/client-detail/project-detail/password-vault/${projectDetail?projectDetail.id:''}`} >Password Vault</Link>
                        <Link to={`/timeTracking/${id}`} >Time Tracking</Link>
                        {
                            projectDetail?.BoardId 
                            ? <Link className='btn btn-primary' to={`/viewBoard/${ projectDetail?.BoardId }`} >View Board</Link>
                            : <button className='sr-cstm-primary' onClick={handlesetShowBoard}>Create Board</button>
                    }
                <Link className='btn btn-primary' to={`/clients/client-detail/project-detail/opportunityDetails/${id}`} >Project Details</Link>
                </div>
            </>
        )}
            <div className='project-task-list'>
                <div className='project-task-card'>
                    <div className='project-task-heading'>
                        <span>Discussions</span>
                        <Link to={`/clients/client-detail/project-detail/discussions/new-discussion/${projectDetail.id}`}><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 12H20M12 4V20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg></Link>
                    </div>
                    <div className='row latestDiscussions'>
                        {
                            projectDetail?.latestDiscussions?.length>0?
                            projectDetail?.latestDiscussions.map(discussion=>
                                <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion.id}`}>
                                    <div className='project-peo-wrap'>
                                        <div className='project-peo-img'>
                                            <LazyImageWithSpinner src={discussion?.addedByUser?.profilePictureUrl} alt={discussion?.addedByUser?.firstName} />
                                        </div>
                                        <p>
                                            {
                                             discussion?.addedByUser?.firstName +' '+ 'added a discussion ' 
                                            }
                                           {discussion?.title}
                                            {
                                               ' on ' + new Date(discussion?.createdAt).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                                })

                                            }
                                        </p>
                                    </div>
                                    </Link>
                            )
                            :'No discussion'
                        }
                    </div>
                    <div className='view-btn'>
                        <Link to={`/clients/client-detail/project-detail/discussions/${projectDetail?projectDetail.id:''}`}>View All
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </Link>
                    </div>
                </div>

<div className='project-task-card'>
    <div className='project-task-heading'>
        <span>Details</span>
    </div>
    <div className='Project-details-outer'>
       <div className='Assigned-pro-type'>
            <span className="assign-text">Assigned Project Type: </span>
            <span>{projectDetail?.projectTypes || 'No project type assigned'}</span> 
       </div>
       <div className='Assigned-pro-type'>
            <span className='assign-text'>Assigned Checklist: </span>
                    {projectDetail?.checklists && projectDetail.checklists.length > 0 ? ( 
            <>                 
                {projectDetail.checklists.map((checklist, index) => (
                    <span key={checklist.id}>
                        {checklist.name}{index < projectDetail.checklists.length - 1 ? ', ' : ''}
                    </span>
                ))}    
            </>
            ) : (
                <span>No checklists assigned</span>
            )}
       </div>
       <button className='sr-cstm-primary quick-btn' onClick={()=>{handleQuickLinks(true)}} >Quick Links</button>
            <div className="quick-link">
            {projectDetail?.links && projectDetail.links.length > 0 ? (
                projectDetail.links.map((link, index) => (
                    <a 
                        key={index} 
                        href={link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ display: 'block', marginBottom: '8px' }}
                    >
                        {link}
                    </a>
                ))
            ) : (
                ''
            )}

            </div>
 
<div className='view-btn'>
  {projectDetail?.checklists && projectDetail.checklists.length > 0 ? (
    <Link
      to={`/projectDetail/${projectDetail?.id}/${
        projectDetail.checklists.length === 1
          ? projectDetail.checklists[0]?.id 
          : projectDetail.checklists.map((checklist) => checklist.id).join(',') 
      }`}
      className="sr-cstm-primary"
    >
      View Checklist
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Link>
  ) : (
    ''
  )}
</div>
    </div>

</div>
            </div>
            <div className='project-file-list'>
                <div className='project-left'>
            <h3>Files</h3>
            {/* <div className='pdf-list'>
                {
                    projectDetail?.files?.length > 0 ? 
                    projectDetail?.files.map((file, index) =>
                        <div key={index} className='pdf-list-data'>
                            <div className='pdf-card'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.083" height="26.777" viewBox="0 0 20.083 26.777">
                                    <path id="Icon_awesome-file-pdf" data-name="Icon awesome-file-pdf" d="M9.513,13.394a5.046,5.046,0,0,1-.1-2.453C9.848,10.941,9.806,12.871,9.513,13.394Zm-.089,2.468a24.132,24.132,0,0,1-1.485,3.279A19.257,19.257,0,0,1,11.228,18,6.775,6.775,0,0,1,9.424,15.862ZM4.5,22.389c0,.042.69-.282,1.825-2.1A7.228,7.228,0,0,0,4.5,22.389ZM12.97,8.368h7.113V25.522a1.252,1.252,0,0,1-1.255,1.255H1.255A1.252,1.252,0,0,1,0,25.522V1.255A1.252,1.252,0,0,1,1.255,0h10.46V7.113A1.259,1.259,0,0,0,12.97,8.368Zm-.418,8.985a5.249,5.249,0,0,1-2.233-2.814,7.517,7.517,0,0,0,.324-3.358,1.31,1.31,0,0,0-2.5-.356,8.7,8.7,0,0,0,.424,4.027A49.11,49.11,0,0,1,6.433,19.34c-.005,0-.005.005-.01.005C5,20.072,2.573,21.673,3.572,22.9a1.625,1.625,0,0,0,1.124.523c.936,0,1.867-.941,3.2-3.232a29.811,29.811,0,0,1,4.132-1.213A7.926,7.926,0,0,0,15.371,20a1.354,1.354,0,0,0,1.03-2.27c-.727-.711-2.84-.507-3.849-.377ZM19.717,5.491,14.591.366A1.254,1.254,0,0,0,13.7,0h-.314V6.694h6.694V6.375A1.251,1.251,0,0,0,19.717,5.491ZM15.841,18.843c.214-.141-.131-.622-2.238-.471C15.543,19.2,15.841,18.843,15.841,18.843Z" fill="#fff" />
                                </svg>
                            </div>
                            <div className='pdf-text'>
                                <span><a href={file.url} onClick={(e) => { e.preventDefault(); handlePreview(file.url); }}>{file.originalname.slice(-15)}</a></span>
                            </div>
                        </div>
                    )
                    : 'no file found'
                }
            </div> */}
            <div className='files-outer'>
            <div className='files-data-inner'>
    {/* Check for files and render them if available */}
    {
        projectDetail?.files?.length > 0 ? (
            projectDetail.files.map((file, index) => (
                <div className='file-grid' key={index}>
                    <div className='file-image'>
                        {getFileDisplaydefault(file.url)}
                    </div>
                    <div className='files-infodata'>
                        <p className='file-name'>{file.originalname}</p>
                        <div className='file-meta'>
                            <span>Added by {file.addedBy} on {formatDate(file.createdAt)}</span>
                        </div>
                    </div>
                </div>
            ))
        ) : null
    }

    {/* Check for media and render if available */}
    


    {/* Show "no file found" if both files and media are empty */}
    {
        (!projectDetail?.files?.length && !projectDetail?.media?.length) && <p></p>
    }
</div>

            </div>
            {isPreviewVisible && 
                <div className='preview-overlay'>
                    <div className='preview-content'>
                        <button className='close-preview' onClick={handleClosePreview}>Close</button>
                        <iframe src={previewUrl} width="100%" height="600px" title="PDF Preview"></iframe>
                    </div>
                </div>
            }
        </div>
                <ArchiveModal showModal={showModal} setShowModal={setShowModal} id={id} company={projectDetail.companyId} />
                <UnArchieveModal showUnModal={showUnModal} setShowUnModal={setShowUnModal} id={id} company={projectDetail.companyId} />
                <AssignChecklistModal 
                    ShowChecklist={ShowChecklist} 
                    setShowChecklist={setShowChecklist} 
                    id={id} 
                    ProjectTypeName= {ProjectTypeName}
                    SelectedChecklist= {SelectedChecklist}
                    onClose={handleClose}    
                    
                />
                <CreateBoard showBoard={showBoard} setShowBoard={setShowBoard} id={id} company={projectDetail.companyId}  onClose={refreshState}  />
                <EditProject showEdit={showEdit} setShowEdit={setShowEdit} id={id}  onClose={refreshState}/>
                <QuickLinks showQuick={showQuick} setShowQuick={setShowQuick} id={id}  onClose={refreshState}/>

                <div className="project-right">
                    <div className='project-task-heading'>
                        <span>Team</span>
                        {
                        showAddTeamMember ?
                        <a href="javascript:void(0)" onClick={closeAddMembersPopup}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </a>
                        :
                        <a href="javascript:void(0)" onClick={AddMembersPopup}>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 12H20M12 4V20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                        }
                    </div>
                    {
                        showAddTeamMember ? 
                        <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="sadsa"
                        />
                        :''
                    }
                    <div className='project-people'>
                        {/* <div className='project-peo-data'>
                            <h2>Client : {projectDetail?.company?.name + ' '}{projectDetail?.email}</h2>
                        </div> */}
                        <div className='project-peo-data'>
                            {/* <h2>Team</h2> */}
                            <div className='project-sr'>   
                            {
                                projectDetail && projectDetail?.users?.length > 0?
                                projectDetail.users.map((team)=>
                                    <Link to={`/teamMember-edit/${team.id}`}>
                                        <div className='project-peo-wrap'>
                                            <div className='project-peo-img'>
                                                <LazyImageWithSpinner src={team.profilePictureUrl} />
                                            </div>
                                            <div className='project-peo-cont'>
                                                <p>{team?.firstName +' '+ team?.lastName} <br></br> {team?.jobTitle}</p>
                                            </div>
                                        </div>
                                    </Link>
                                )  
                                :'No team member found'
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
        </div>
    )
}

export default ProjectDetail