import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

function QuickLinks({ showQuick, setShowQuick, id, onClose }) {
  const [timer, setTimer] = useState(null);
  const [projectQuickLinks, setProjectQuickLinks] = useState(['']); // Initialize as an array with one empty string
  const userData = useSelector(state => state.auth.user);

  const handleClose = () => {
    clearTimeout(timer);
    onClose();
    setShowQuick(false);
  };

  const editProjectLinks = async () => {
    try {
      const response = await API.post(`/admin/ProjectQuickLinks/${id}`, {
        userId: userData.user.id,
        links: projectQuickLinks,
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        const timerId = setTimeout(() => {
          handleClose();
        }, 500);
        setTimer(timerId);
      } else {
        toast.error('Failed to update project links');
      }
    } catch (error) {
      console.error('Error updating project links:', error);
      toast.error('Error updating project links');
    }
  };

  const getProjectLinks = async () => {
    try {
      const response = await API.get(`/admin/ProjectQuickLinks/${id}`);
      
      if (response.status === 200) {
        // Ensure response data is an array
        const links = Array.isArray(response.data.projectLinks.links) 
          ? response.data.projectLinks.links 
          : [''];
        setProjectQuickLinks(links);
      } else {
        toast.error('Failed to fetch project links');
      }
    } catch (error) {
      console.error('Error fetching project links:', error);
      toast.error('Error fetching project links');
    }
  };

  const handleLinkChange = (index, newLink) => {
    setProjectQuickLinks(prevLinks =>
      prevLinks.map((link, i) => (i === index ? newLink : link))
    );
  };

  const addNewLink = () => {
    setProjectQuickLinks([...projectQuickLinks, '']);
  };

  const removeLink = index => {
    setProjectQuickLinks(prevLinks => prevLinks.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (showQuick) {
      getProjectLinks();
    }
  }, [showQuick]);

  return (
    <>
      <Modal centered show={showQuick} onHide={handleClose} className='add-team-modal quick-link'>
        <Modal.Header closeButton>
          <h4 className="modal-title">Quick Links</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            {projectQuickLinks.map((link, index) => (
              <div key={index} className="link-input-group">
                <input
                  type='text'
                  value={link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                  className='sr-edit-project'
                  placeholder={`Enter Link ${index + 1}`}
                />
                <button
                  type="button"
                  onClick={() => removeLink(index)}
                  className="btn remove-link-btn"
                >
                  <img src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e"></img>
                </button>
              </div>
            ))}
            <div className='add-link-quick'><button type="button" onClick={addNewLink} className="btn add-link-btn global-icon-btn">
              Add Link
            </button></div>
          </div>
          <div className="form-btn-rows">
            <button type="button" onClick={editProjectLinks} className="btn global-icon-btn orange-btn">
              Update
            </button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">
              Cancel
            </button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light'
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QuickLinks;
