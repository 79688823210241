import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 
import moment from 'moment';  // For handling time

function ViewChecklist() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { checklistId, projectId } = useParams();  
    const [title, setTitle] = useState('CheckList');
    const [tasks, setTasks] = useState([]);
    const [projectDetails, setProjectDetails] = useState('');
    const [loading, setLoading] = useState(true);
    const [additionalDetails, setAdditionalDetails] = useState([]);
    const [checkedTasks, setCheckedTasks] = useState({});
    const [checklistArray, setChecklistArray] = useState([]);  
    const [checklistNames, setChecklistNames] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const [comments, setComments] = useState({});
    const [commentInputVisible, setCommentInputVisible] = useState({});
    const userData = useSelector(state => state.auth.user);

    const fetchData = async (checklistId) => {
        try {
            const taskResponse = await API.get(`/admin/checklists/${checklistId}/${projectId}/tasks`);
            setTasks(taskResponse.data.tasks);
            setProjectDetails(taskResponse.data.projectDetails);
            dispatch(setBreadcrumbs([
                { label: "Home", path: "/" },
                { label: "Clients", path: "/clients" },
                { label: taskResponse.data.projectDetails?.company?.name, path: `/clients/client-detail/${taskResponse.data.projectDetails?.company?.id}` },
                { label: taskResponse.data.projectDetails.opportunityName, path: `/clients/client-detail/project-detail/${taskResponse.data.projectDetails.id}` },
                { label: `Checklist`, path: `#`, active: true },
            ]));
            
            const additionalResponse = await API.get(`/admin/checklists/${checklistId}/${projectId}/additional-details`);
            setAdditionalDetails(additionalResponse.data);

            const initialCheckedTasks = additionalResponse.data.reduce((acc, detail) => {
                acc[detail.taskId] = { status: detail.status, checkedAt: detail.checkedAt };
                return acc;
            }, {});
            setCheckedTasks(initialCheckedTasks);

            const initialComments = additionalResponse.data.reduce((acc, detail) => {
                acc[detail.taskId] = detail.comment || '';  // Populate existing comments
                return acc;
            }, {});
            setComments(initialComments);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to load data');
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            dispatch(pageTitle(title));
            try {
                const Checklistdata = await API.get(`/admin/checklists/names`, {
                    params: { checklistIds: checklistId }
                });
                const checklistNamesData = Checklistdata?.data?.checklists?.map(item => item.name);
                console.log("checklistNamesData", checklistNamesData);
                setChecklistNames(checklistNamesData);

                if (checklistId) {
                    const ids = checklistId.split(',');
                    setChecklistArray(ids);

                    if (ids.length > 0) {
                        fetchData(ids[0]);
                    }
                } else {
                    console.error('checklistId is undefined or null.');
                    toast.error('Invalid checklist IDs');
                }
            } catch (error) {
                console.error('Error fetching checklist data:', error);
                toast.error('Error fetching checklist data');
            }
        };
    
        fetchDataAsync();
    }, [checklistId, projectId, dispatch, title]);

    const handleCheckboxChange = async (taskId) => {
        const currentTime = moment();
        const taskCheckedAt = checkedTasks[taskId]?.checkedAt;
        const timeElapsed = moment.duration(currentTime.diff(taskCheckedAt)).asMinutes();

        if (userData.user.roleId !== 1 && checkedTasks[taskId]?.status && timeElapsed > 15) {
            toast.error('You cannot uncheck this task after 15 minutes');
            return;
        }

        const updatedStatus = !checkedTasks[taskId]?.status;
        setCheckedTasks((prev) => ({
            ...prev,
            [taskId]: { status: updatedStatus, checkedAt: currentTime }
        }));

        const currentChecklistId = checklistArray[activeTab]; // Get the checklist ID for the active tab

        if (updatedStatus) {
            setCommentInputVisible((prev) => ({
                ...prev,
                [taskId]: true  // Show comment box when task is checked
            }));
        } else {
            setCommentInputVisible((prev) => ({
                ...prev,
                [taskId]: false  // Hide comment box when task is unchecked
            }));
        }

        try {
            const response = await API.post(`/admin/tasks/update-status`, {
                checklistId: currentChecklistId, // Use the current checklist ID
                projectId,
                taskId,
                userId: userData.user.id,
                status: updatedStatus, 
                checkedAt: currentTime 
            });

            if (response.data.success) {
                toast.success('Task status updated successfully');
                fetchData(currentChecklistId); // Fetch tasks for the current checklist ID
            } else {
                throw new Error('Failed to update task status');
            }
        } catch (error) {
            console.error('Error updating task status:', error);
            setCheckedTasks((prev) => ({
                ...prev,
                [taskId]: { status: !updatedStatus, checkedAt: taskCheckedAt }
            }));
            toast.error('Failed to update task status');
        }
    };

    const handleCommentChange = (taskId, comment) => {
        setComments((prev) => ({
            ...prev,
            [taskId]: comment,
        }));
    };

    const handleCommentSubmit = async (taskId) => {
        const currentChecklistId = checklistArray[activeTab]; // Use the current checklist ID from the selected tab
        try {
            const response = await API.post(`/admin/tasks/add-comment`, {
                taskId,
                checklistId: currentChecklistId, // Pass the active checklist ID
                projectId,
                comment: comments[taskId],
                userId: userData.user.id,
            });
            if (response.data.success) {
                toast.success('Comment added successfully');
                setCommentInputVisible((prev) => ({
                    ...prev,
                    [taskId]: false  // Hide comment box after submitting
                }));
                fetchData(currentChecklistId);  // Refetch tasks for the active checklist ID
            } else {
                throw new Error('Failed to add comment');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
            toast.error('Failed to add comment');
        }
    };
    

    const getCompletionDetails = (taskId) => {
        const detail = additionalDetails.find(detail => detail.taskId === taskId);
        return detail ? { completedBy: detail.userDetails.firstName, time: detail.completedAt } : { completedBy: '', time: '' };
    };

    const handleChecklistChange = (e) => {
        const selectedId = e.target.value;
        const selectedIndex = checklistArray.indexOf(selectedId);
        setActiveTab(selectedIndex);
        fetchData(selectedId);  // Fetch tasks for the selected checklist
    };

    return (
        <div className='main-dashboard-admin checklists'>
            <div className='view-check'>
                <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>

                {checklistNames.length > 0 && (
                    <div className="project-btn">
                        <label htmlFor="checklistDropdown">Select Checklist:</label>
                        <select id="checklistDropdown" onChange={handleChecklistChange}>
                            {checklistNames.map((name, index) => (
                                <option key={index} value={checklistArray[index]}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                </div>

            <div className='teamInfoTable email-table checklist'>
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="name">Done</th>
                            <th className="projectName">Task</th>
                            <th className="projectName">Completed By</th>
                            <th className="projectName">Time</th>
                            <th className="projectName">Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.length > 0 ? (
                            tasks.map((task) => {
                                const { completedBy, time } = getCompletionDetails(task.id);
                                return (
                                    <tr key={task.id}>
                                        <td className="name">
                                            <input
                                                className="custom-checkbox"
                                                type="checkbox"
                                                checked={checkedTasks[task.id]?.status || false} 
                                                onChange={() => handleCheckboxChange(task.id)} 
                                            />
                                        </td>
                                        <td className="projectName">{task.description}</td>
                                        <td className="projectName">{completedBy}</td>
                                        <td className="projectName">
                                            {time ? new Date(time).toLocaleString('en-US', { 
                                                month: '2-digit', 
                                                day: '2-digit', 
                                                year: 'numeric', 
                                                hour: '2-digit', 
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true // This makes it a 12-hour clock with AM/PM
                                            }) : ''}
                                        </td>

                                        <td className="projectName comnt">
                                            {comments[task.id] && !commentInputVisible[task.id] ? (
                                                <>
                                                    <p>{comments[task.id]}</p>
                                                    <button onClick={() => setCommentInputVisible((prev) => ({ ...prev, [task.id]: true }))}>+</button>
                                                </>
                                            ) : (
                                                checkedTasks[task.id]?.status && (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={comments[task.id] || ''}
                                                            onChange={(e) => handleCommentChange(task.id, e.target.value)}
                                                        />
                                                        <button onClick={() => handleCommentSubmit(task.id)}>Add Comment</button>
                                                    </>
                                                )
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <div className="no-data-found no-padding"><span>No tasks found.</span></div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <ToastContainer theme='light' />
        </div>
    );
}

export default ViewChecklist;
